import { put, call } from 'redux-saga/effects';
import { setIsLoading } from '../actions/common';
import API from '../../api';
import { setItem } from '../actions/validation';
import { loadServiceProvidersSaga } from './serviceProviders';

export function* loadValidationItemSaga(action) {
  const { id } = action.payload;
  try {
    yield put(setIsLoading(true));
    const data = yield API.rest.reports.item(id);
    yield put(setItem({ ...data }));
    yield put(setIsLoading(false));
    if (data.json.contractorAreaId) {
      yield call(loadServiceProvidersSaga);
    }
  } catch (err) {
    yield put(setIsLoading(false));
  }
}
